import { post } from '@/utils/request';

// 获取库存警告列表
const getListReq = (params) => post({
  url: '/store/goods.GoodsTypeWarning/list',
  data: {
    ...params,
  },
});

// 创建滞销预警
const createReq = (params) => post({
  url: '/store/goods.GoodsTypeWarning/create',
  data: {
    ...params,
  },
});

// 删除滞销预警
const deleteReq = (params) => post({
  url: '/store/goods.GoodsTypeWarning/delete',
  data: {
    ...params,
  },
});

// 编辑滞销预警
const editReq = (params) => post({
  url: '/store/goods.GoodsTypeWarning/edit',
  data: {
    ...params,
  },
});

export {
  getListReq,
  createReq,
  deleteReq,
  editReq,
};
