<template>
  <div v-if="!addVisible">
    <div class="card">
      <el-button type="primary" @click="addVisible = true">
        <i class="el-icon-plus"></i>
        新增滞销预警
      </el-button>
    </div>
    <div class="table">
      <el-table
        :data="tableData.list"
        style="width: 100%"
        v-loading="loading"
        height="700"
        stripe
      >
        <template v-for="item in rowList">
          <el-table-column
            :key="item.field_alias"
            :prop="item.field_alias"
            :label="item.field_text"
            align="center"
            :width="item.t_width">
            <template slot-scope="scope">
              <template v-if="item.field_alias !== 'orange_days' && item.field_alias !== 'yellow_days' && item.field_alias !== 'red_days'">
                {{scope.row[item.field_alias] ? scope.row[item.field_alias] : '--'}}
              </template>
              <template v-else>
                {{scope.row[item.field_alias] ? '大于 ' + scope.row[item.field_alias] + ' 天' : '--'}}
              </template>
            </template>
          </el-table-column>
        </template>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="handleChange(scope.row)">修改</el-button>
            <el-button type="text" @click="handleDele(scope.row)" style="color: #F56C6C;">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagina">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="$store.state.pageSizes"
        :page-size="$store.state.pageSizes[0]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.total">
      </el-pagination>
    </div>
  </div>
  <Add v-else @onHide="handleHide" :row="currRow" />
</template>

<script>
import { getListReq, deleteReq } from '@/api/system/inventoryWarning';
import Add from './Add.vue';

export default {
  data() {
    return {
      currRow: {},
      addVisible: false,
      loading: false,
      page: 1,
      page_num: this.$store.state.pageSizes[0],
      rowList: [
        { field_text: '序号', field_alias: 'index' },
        { field_text: '品类', field_alias: 'goods_type_name' },
        // { field_text: '正常', field_alias: 'normal_days' },
        { field_text: '黄色预警', field_alias: 'yellow_days' },
        { field_text: '橙色预警', field_alias: 'orange_days' },
        { field_text: '红色预警', field_alias: 'red_days' },
      ],
      tableData: {
        list: [],
        total: 0,
      },
    };
  },
  created() {
    this.getList();
  },
  components: {
    Add,
  },
  methods: {
    // add组件接收事件
    handleHide(getList) {
      if (getList) {
        this.getList();
      }
      this.addVisible = false;
      this.currRow = {};
    },
    handleDele(row) {
      this.$confirm('确定要删除该品类的滞销预警吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        deleteReq({
          goods_type_warning_id: row.goods_type_warning_id,
        })
          .then((res) => {
            if (res.code === 1) {
              this.$message({
                type: 'success',
                message: '删除成功!',
              });
            }
            this.getList();
          });
      });
    },
    handleChange(row) {
      this.currRow = { ...row };
      this.addVisible = true;
    },
    // 获取列表
    getList() {
      this.loading = true;
      getListReq({
        page: this.page,
        page_num: this.page_num,
      })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            res.data.list.forEach((item, index) => {
              item.index = index + 1;
            });
            this.tableData = res.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 分页条数改变
    handleSizeChange(page_num) {
      this.page = 1;
      this.page_num = page_num;
      this.getList();
    },
    // 分页页数改变
    handleCurrentChange(page) {
      this.page = page;
      this.getList();
    },
  },
};
</script>
