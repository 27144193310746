<template>
  <div>
    <div class="card">
      <el-page-header
        @back="goBack"
        :content="row.goods_type_id ? '编辑库存预计' : '新增滞销预警'"
      >
      </el-page-header>
    </div>
    <div class="form">
      <el-form label-width="120px">
        <el-form-item label="选择品类" required v-if="!row.goods_type_id">
          <el-select
            v-model="form.select"
            @click.native="treeVisible = true"
          ></el-select>
        </el-form-item>
        <el-form-item
          :label="item.goods_type_name"
          required
          v-for="item in insert_data"
          :key="item.goods_type_id"
        >
          <!-- <div style="display: flex; margin-bottom: 10px;">
            <div style="width: 60px;">正常</div>
            <el-input v-model="item.normal_days" style="width: 15%; margin-left: 20px;"></el-input>
          </div> -->
          <div style="display: flex; margin-bottom: 10px">
            <div style="width: 60px">黄色预警</div>
            <el-input
              v-model="item.yellow_days"
              style="width: 15%; margin-left: 20px"
            >
              <template slot="prepend">大于</template>
              <template slot="append">天</template>
            </el-input>
          </div>
          <div style="display: flex; margin-bottom: 10px">
            <div style="width: 60px">橙色预警</div>
            <el-input
              v-model="item.orange_days"
              style="width: 15%; margin-left: 20px"
            >
              <template slot="prepend">大于</template>
              <template slot="append">天</template>
            </el-input>
          </div>
          <div style="display: flex; margin-bottom: 10px">
            <div style="width: 60px">红色预警</div>
            <el-input
              v-model="item.red_days"
              style="width: 15%; margin-left: 20px"
            >
              <template slot="prepend">大于</template>
              <template slot="append">天</template>
            </el-input>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">保存</el-button>
        </el-form-item>
      </el-form>
      <el-dialog
        title="选择品类"
        :visible.sync="treeVisible"
        width="80%"
        :before-close="handleClose"
      >
        <el-tree
          class="tree-line"
          ref="tree"
          :data="treeData"
          show-checkbox
          :check-strictly="true"
          node-key="goods_type_id"
          :props="defaultProps"
        >
        </el-tree>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
          <el-button type="primary" @click="handleTrue" :loading="loading"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  getListReq as getTableListReq,
  createReq,
  editReq,
} from "@/api/system/inventoryWarning";
import { getListReq } from "@/api/goods/goodsConfig/classify";

export default {
  data() {
    return {
      loading: false,
      treeVisible: false,
      form: {},
      insert_data: [],
      normal_days: "",
      treeData: [],
      defaultProps: {
        children: "children",
        label: "goods_type_name",
      },
    };
  },
  props: {
    row: {
      type: Object,
    },
  },
  created() {
    this.getList();
    if (this.row.goods_type_id) {
      this.insert_data.push(this.row);
    }
  },
  methods: {
    // 提交数据
    onSubmit() {
      this.loading = true;
      if (!this.row.goods_type_id) {
        createReq({
          insert_data: this.insert_data,
        })
          .then((res) => {
            if (res.code === 1) {
              this.loading = false;
              this.$message.success("操作成功");
              this.$emit("onHide", "getList");
            }
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        const params = this.insert_data[0];
        editReq({
          ...params,
        })
          .then((res) => {
            if (res.code === 1) {
              this.loading = false;
              this.$message.success("操作成功");
              this.$emit("onHide", "getList");
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    // 获取的key
    getCheckedNodes() {
      const nodes = this.$refs.tree.getCheckedNodes();
      if (!nodes.length) {
        this.form.select = '';
        return;
      }
      const form = { ...this.form };
      form.select = nodes[0].goods_type_name + (nodes.length > 1 ? " 等" : "");
      this.form = form;
      // 组装后端需要的数据
      nodes.forEach((item) => {
        const obj = {
          goods_type_id: item.goods_type_id,
          goods_type_name: item.goods_type_name,
          normal_days: "",
          yellow_days: "",
          orange_days: "",
          red_days: "",
        };
        this.insert_data.push(obj);
      });
    },
    handleClose() {
      this.treeVisible = false;
    },
    handleTrue() {
      this.insert_data = [];
      this.getCheckedNodes();
      this.handleClose();
    },
    getList() {
      getListReq().then((res) => {
        if (res.code === 1) {
          this.treeData = res.data;
          getTableListReq({
            page: 1,
            page_num: 999999,
          }).then((result) => {
            if (result.code === 1) {
              console.log(result.data.list);
              this.handleArr(this.treeData, result.data.list);
            }
          });
        }
      });
    },
    handleArr(arrList, dataList) {
      dataList.forEach((data) => {
        arrList.forEach((arr) => {
          if (data.goods_type_id === arr.goods_type_id) {
            arr.disabled = true;
          }
          if (arr.children) {
            this.handleArr(arr.children, dataList);
          }
        });
      });
    },
    goBack() {
      this.$emit("onHide");
    },
  },
};
</script>

<style lang="less" scoped>
.form {
  background: white;
  padding: 20px 0;
  margin-top: 10px;
}
</style>
